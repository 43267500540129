<template>
  <div class="distribution-again">
    <div class="distribution-body">
      <div class="distribution-title">
        <!-- 重新分配批准请求 -->
        {{ $t('label.redistributionrequest') }}
      </div>
      <div class="distribution-body">
        <div class="title">
          <!-- 重新分配批准请求 -->
          {{ $t('label.redistributionrequest') }}
        </div>
        <div class="body">
          <el-form
            :model="formData"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <!-- <el-form-item label="批准人" prop="people"> -->
            <el-form-item :label="$t('label.approver')" prop="people">
              <el-input
                v-model="formData.people"
                readonly="readonly"
              ></el-input>
              <!-- 查找 -->
              <span class="search-button" @click="searchPeo"
                >({{ $t('label.ems.lookup2') }})</span
              >
            </el-form-item>
            <!-- 备注 -->
            <el-form-item :label="$t('label.description')">
              <el-input type="textarea" v-model="formData.remarks"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                @click="submitForm('ruleForm')"
                class="leftdiss"
                :loading="approvalLoading"
              >
                <!-- 重新分配批准请求 -->
                {{ $t('label.redistributionrequest') }}</el-button
              >
              <el-button @click="cancel('ruleForm')" :loading="approvalLoading">
                <!-- 取消 -->
                {{ $t('label.cancel') }}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <batch-list :butchValue="batchValue"></batch-list>
      </div>
    </div>
    <div class="shuttle-box">
      <el-dialog
        :title="$t('label.selectapprover')"
        :visible.sync="isSearch"
        width="500px"
      >
        <shuttle-box
          @changeSearch="changeSearch"
          @deliverName="deliverName"
        ></shuttle-box>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import shuttleBox from "@/views/approval/components/shuttle-box.vue";
  import batchList from "@/views/approval/components/batch-list.vue";
  import { batchApproval } from "./api.js";

  export default {
    name: "distribution-again",
    data () {
      return {
        approvalLoading: false,
        isSearch: false,
        //审批人ID
        fprId: "",
        formData: {
          people: "",
          remarks: ""
        },
        rules: {
          people: [
            {
              required: true,
              message: this.$i18n.t("label.value.notnull"),
              trigger: "blur"
            }
          ]
        },
        //批量审批获取到的值
        batchValue: JSON.parse(this.$route.query.value)
      };
    },
    computed: {
      workItemId () {
        let arr = this.batchValue.map(res => {
          return res.workItemid;
        });
        arr = arr.join(";");
        return arr;
      }
    },
    components: {
      shuttleBox,
      batchList
    },
    mounted () {
      if (this.$route.path == "/approvalCenter/batchDistributionAagain") {
        document.title = this.$setTitle(this.$i18n.t('label.accraditation'));
      }
    },
    methods: {
      //获取审批人名称和ID
      deliverName (name, id) {
        this.formData.people = name;
        this.fprId = id;
      },
      async batchApprovalRequest () {
        let option = {
          comments: this.formData.remarks,
          workItemId: this.workItemId,
          actionType: "Reassign",
          fprId: this.fprId
        };
        try {
          let result = await batchApproval(option);
          if (result.returnCode == "1") {
            this.approvalLoading = false;
            this.$router.push("/approvalCenter");
            this.$Bus.$emit("cancel-not-loading", true);
          } else {
            this.$message({showClose: true,
              type: "warning",
              message: result.stackTrace || result.returnInfo
            });
            this.approvalLoading = false;
            this.$router.push("/approvalCenter");
          }
        } catch {
          this.approvalLoading = false;
          this.$router.push("/approvalCenter");
        }
      },
      //收起弹出框
      changeSearch () {
        this.isSearch = false;
      },
      //查找批准人
      searchPeo () {
        this.isSearch = true;
      },
      submitForm (formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.approvalLoading = true;
            this.batchApprovalRequest();
          } else {
            return false;
          }
        });
      },
      cancel () {
        this.$router.push("/approvalCenter");
      }
    }
  };
</script>

<style lang='scss' scoped>
  .distribution-again {
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: auto;
    .distribution-body {
      width: 100%;
      .distribution-title {
        height: 49px;
        font-size: 18px;
        background: #f8f8f8;
        font-weight: 500;
        color: #222222;
        line-height: 49px;
        padding-left: 20px;
      }
      .distribution-body {
        .title {
          margin-top: 14px;
          margin-left: 20px;
          font-size: 14px;
          color: #222;
        }
        .body {
          width: 60%;
          margin: 20px auto;
          .el-form {
            .search-button {
              cursor: pointer;
              color: #006dcc;
              font-size: 12px;
              margin-left: 20px;
            }
            .is-have {
              font-size: 12px;
              margin-left: 100px;
            }
            .el-input {
              width: 216px;
            }
            .el-form-item__label {
              font-size: 12px;
            }
            .el-input__inner {
              width: 216px;
              height: 30px;
              line-height: 30px;
            }
            .el-textarea {
              width: 431px;
            }
            .el-textarea__inner {
              height: 163px;
              resize: none;
            }
            .submit-button {
              cursor: pointer;
              width: 127px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              color: #006dcc;
              font-size: 12px;
              border-radius: 3px;
              border: 1px solid #dddbda;
              float: right;
              margin-right: 15px;
              font-weight: 400;
            }
            .cancels-button {
              margin-right: 240px;
              cursor: pointer;
              width: 50px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              color: #006dcc;
              font-size: 12px;
              border-radius: 3px;
              border: 1px solid #dddbda;
              float: right;
              font-weight: 400;
            }
          }
        }
      }
    }
    .leftdiss {
      margin-left: 230px;
    }
    .el-button {
      padding: 6px 15px;
      color: #006dcc;
      font-size: 12px;
    }
  }
</style>